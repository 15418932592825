// const rellax = require('rellax');
const navbar = document.getElementById("navbar");
const sticky = navbar.offsetTop;
// const parallax = rellax('.rellax');
const popup = document.querySelector('.full-screen');
const body = document.querySelector('body');
const videoElement = document.getElementById('video')

window.onscroll = function() { onScroll() };
window.onresize  = function () { onResize() };
document.getElementById("btnOpenPopup").addEventListener("click", togglePopup);
document.getElementById("btnClosePopup").addEventListener("click", togglePopup);

function onScroll() {
  if (window.pageYOffset >= sticky + 40) {
    navbar.classList.add("sticky")
  } else {
    navbar.classList.remove("sticky");
  }
}

function onResize(){
  // var BreakPoint = 680; // pixeles

  // if(window.innerWidth < BreakPoint){
    // parallax.destroy();
  // } else {
    // parallax.refresh();
  // }
}

function togglePopup() {
  body.classList.toggle('popup-open');
  popup.classList.toggle('hidden');
  videoElement.pause();
  videoElement.currentTime = 0;
}

onResize();
